<template>

  <div class="lecture">

    <div>

      <div class="next-lesson-overlay d-flex" v-if="showLessonOverlay">

        <div class="content align-self-center text-center">

          <v-btn
            class="save-note"
            color="primary"
            @click="gotoNextLesson"
            v-if="
              !lesson.nextLesson ||
                (lesson.nextLesson && lesson.nextLesson.sectionId == lesson.sectionId)
            "
          >
             {{lesson.nextLesson && lesson.nextLesson.type === "exercise"? "Begin Exercise":lesson.nextLesson
            && lesson.nextLesson.parentLessonId ? "Next Video" : "NextLesson"}}
          </v-btn>

        </div>

      </div>

      <div id="video-wrapper">

        <!-- :key="videoSrc" -->

        <video ref="mainVideo" controls controlslist="nodownload">

          <source type="video/mp4" />

        </video>

      </div>

      <!-- <div id="video-controls">
        <v-btn @click="play" text color="primary">play &gt;</v-btn>
        <v-btn @click="pause" text color="primary">pause ||</v-btn>
      </div>-->

    </div>

  </div>

</template>

<style lang="scss" scoped>
.lecture {
  position: relative;
}
.next-lesson-overlay {
  margin: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 2;
  // max-width: 900px;
  .content {
    margin: auto;
    width: 200px;
    color: white;
  }
}
#video-wrapper {
  margin: auto;
  width: 100%;
  // max-width: 900px;
}
video {
  width: 100% !important;
  height: auto !important;
}
</style>

<script>
import { mapState } from "vuex";
import analytics from "../module/analytics";
import progress from "../module/progress";

export default {
  data() {
    return {
      videoSrc: null,
      triggeredEx1: false,
      lessonId: null,
      showLessonOverlay: false,
      triggeredEvents: [],
      lastProgressTimeSent: 0,
      videoCurrentTime: 0
    };
  },
  computed: {
    videoCensored() {
      return this.$store.state.auth.videoCensored;
    },
    ...mapState(["auth"])
  },
  props: {
    lesson: Object,
    section: Object,
    bandwidth: String,
    playVideo: Boolean
  },
  watch: {
    videoCensored(newVal) {
      console.log("auth->videoCensored+++++++===+++++=====++", newVal);
      this.setVideo();
    },
    lesson(newVal) {
      console.log("auth->videoCensored+++++++===+++++=====++", newVal);
      this.showLessonOverlay = false;
      this.setVideo();
    },
    bandwidth(newVal) {
      console.log("auth->videoCensored+++++++===+++++=====++", newVal);
      this.setVideo();
    },
    playVideo(newVal) {
      console.log("playVideo->playVideo+++++++===+++++=====++", newVal);
      if (newVal) {
        const mainVideo = this.$refs.mainVideo;
        mainVideo.play();
      }
    }
  },
  mounted() {
    const self = this;
    this.setVideo(this.$route.query.pickup);
    this.lessonId = this.lesson._id;
    this.exerciseId = this.lesson._id;
    this.showLessonOverlay = false;
    analytics.page("lecture", [{ name: "path", value: this.$route.path }]);
    analytics.track("View Video", [
      { name: "lessonId", value: self.lesson._id },
      { name: "sectionId", value: self.lesson.section._id }
    ]);

    // track video events
    const mainVideo = this.$refs.mainVideo;

    const events = [
      "abort",
      "ended",
      "error",
      "pause",
      "play",
      "ratechange",
      "seeked",
      "seeking",
      "volumechange"
    ];
    events.forEach(x => {
      // console.log("ev - ", `on${x}`);
      mainVideo[`on${x}`] = async e => {
        const video = e.currentTarget;
        const eventName = `${x.charAt(0).toUpperCase()}${x.slice(1)}`;

        if (!x === "seeking" && event.target.currentTime > this.videoCurrentTime) {
          this.videoCurrentTime = event.target.currentTime;
        }

        if (x === "seeking") {
          console.log("self.lesson.locked", self.lesson.locked);
          console.log("x", x);
        }

        if (x === "seeking" && (self.lesson.blockSeek || self.lesson.locked)) {
          var delta = video.currentTime - self.videoCurrentTime;
          if (Math.sign(delta) != -1 && Math.abs(delta) > 0.1) {
            video.currentTime = self.videoCurrentTime;
            alert("No skipping ahead!");
            return;
          }
        }

        let triggerQuestion;
        self.lesson.questions.forEach(q => {
          if (q.triggerEvents && !triggerQuestion) {
            const answer = q.triggerEvents.find(
              te => te.name == x && te.lessonId == self.lesson._id
            );
            if (answer) {
              triggerQuestion = q;
            }
          }
        });

        if (triggerQuestion) {
          event.target.pause();
          self.triggeredEx1 = true;

          // self.$emit("update:exercisePart", triggerQuestion.partNumber);
          self.$emit("update:showDialog", {
            question: triggerQuestion
          });
        } else {
          if (x === "ended") {
            const result = await progress.update(
              "lesson ended",
              self.lesson.section._id,
              self.lesson._id
            );

            if (
              self.lesson.nextLesson &&
              self.lesson.nextLesson.sectionId != self.lesson.sectionId
            ) {
              self.gotoNextLesson();
            } else {
              self.showLessonOverlay = true;
            }

            // gotoNextLesson()

            this.$emit("ended");
            // refresh menu
          }
        }

        if (x !== "pause" || (x === "pause" && video.currentTime !== video.duration)) {
          analytics.track(`Video ${eventName}`, [
            { name: "lessonId", value: self.lesson._id },
            { name: "sectionId", value: self.lesson.section._id },
            { name: "currentTime", value: video.currentTime.toString() },
            { name: "videoSrc", value: self.videoSrc }
          ]);
        }
      };
    });

    mainVideo.ontimeupdate = event => {
      // console.log("The currentTime=.", event.target.currentTime);
      const currentTime = event.target.currentTime;
      // const triggerQuestion = this.lesson.questions.find(x=>currentTime >= x.partTriggerTime && this.triggeredEx1 === false);
      if (!event.target.seeking) {
        if (event.target.currentTime > this.videoCurrentTime) {
          this.videoCurrentTime = event.target.currentTime;
        }

        let triggerQuestion;
        const self = this;
        if (this.lesson) {
          this.lesson.questions.forEach(q => {
            if (q.triggerEvents && !triggerQuestion) {
              const triggerEvent = q.triggerEvents.find(
                te =>
                  te.time &&
                  currentTime >= te.time &&
                  te.lessonId == self.lesson._id &&
                  self.triggeredEvents.indexOf(te.time) == -1
              );
              // console.log("event", event, "currentTime", currentTime / 60);
              if (triggerEvent) {
                self.triggeredEvents.push(triggerEvent.time);
                triggerQuestion = q;
              }
            }
          });
          // console.log("Math.floor(currentTime) " + Math.floor(currentTime));
          if (
            Math.floor(currentTime) != 0 &&
            Math.floor(currentTime) % 10 === 0 &&
            this.lastProgressTimeSent != Math.floor(currentTime)
          ) {
            this.lastProgressTimeSent = Math.floor(currentTime);
            console.log("10 sec");
            progress.update(
              "video progress",
              self.lesson.section._id,
              self.lesson._id,
              Math.floor(currentTime)
            );
          }

          // if (currentTime > 1.941217 && this.triggeredEx1 === false) {
          if (triggerQuestion) {
            event.target.pause();

            self.$emit("update:exercisePart", triggerQuestion.partNumber);
            this.$emit("update:showDialog", true);
          }
        }
      }
    };

    // mainVideo.addEventListener('timeupdate', (event) => {
    //   console.log('The currentTime attribute has been updated. Again.', event);
    // });

    // mainVideo.addEventListener('change', () => {
    // });
  },
  methods: {
    // closeExerciseDialog() {
    //   const mainVideo = this.$refs.mainVideo;
    //   mainVideo.play();
    // },
    gotoNextLesson() {
      const nextLessonId = this.lesson.nextLessonId ? this.lesson.nextLessonId : null;
      //  !self.lesson.unlockNextLesson
      this.$emit("ended", nextLessonId, true);
    },
    setVideo(seek) {
      this.videoSrc = null;
      const mainVideo = this.$refs.mainVideo;
      let vidUrl = this.lesson.videoURL;
      if (this.bandwidth == "low") {
        // const parts = vidUrl.split('/');
        vidUrl = vidUrl.replace("asg-video/", "asg-video/low/");
      } else {
        vidUrl = vidUrl.replace("asg-video/", "asg-video/high/");
      }
      // asg-video
      // if (this.auth.videoCensored) {
      // mainVideo.src = vidUrl.replace(".mp4", "_censored.mp4");
      // 'https://storage.googleapis.com/asg-video/asg_test_001_censored.mp4';
      // } else {
      //  <p>{{ $t('vid_lang_name') }} lang- {{$i18n.locale}}</p>
      // https://storage.googleapis.com/asg-video/low/welcome/Intro_1.mp4

      mainVideo.src = vidUrl.replace(
        "/low/",
        this.$i18n.locale && this.$i18n.locale != "en" ? `/${this.$i18n.locale}/low/` : "/low/"
      );
      // 'https://storage.googleapis.com/asg-video/asg_test_001.mp4';
      // }
      //  set video progress
      // check if  lecture
      if (
        this.section.progress &&
        this.section.progress.videoTime &&
        this.section.progress.lessonId == this.lesson._id &&
        seek
      ) {
        mainVideo.currentTime = this.section.progress.videoTime;
      }
      try {
        mainVideo.play();
      } catch (error) {}
      this.$forceUpdate();
    },
    play() {
      const mainVideo = this.$refs.mainVideo;
      mainVideo.play();
    },
    pause() {
      const mainVideo = this.$refs.mainVideo;
      mainVideo.pause();
    }
  }
};
</script>

