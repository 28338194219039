<template>
  <div>
    <div class="py-3 pl-8">
      <NavTrail
        :links="[{ text: 'Gap Selling', href: '/course-list' }, { text: section.name }]"
      ></NavTrail>
    </div>

    <div class="menu pa-8" v-if="lessons">
      <div class="mb-0">
        <div class="lesson-title headline primary--text text-uppercase mt-0 font-weight-bold">
          {{ section.name }}
        </div>

        <div class="mb-12 mt-3">
          <v-progress-linear
            :rounded="true"
            :value="(lessons.filter((x) => x.completed).length / lessons.length) * 100"
            color="#C7070E"
            background-color="#ffffff"
            height="10"
          ></v-progress-linear>
        </div>
        <div class="text-center" v-if="$i18n.locale != 'en'">
          <v-btn
            class="mx-auto"
            download="exercise_downloads"
            color="primary"
            target="_blank"
            :href="`https://api.gapsellingonlinetraining.com/asg-video/exercise_downloads/${$i18n.locale}/${section.slug}.pdf`"
          >
            {{ $t("download_exercises") }}
          </v-btn>
        </div>
        <div class="mb-1 mt-12">
          <div class="d-flex font-weight-bold">
            <span class="headline font-weight-bold">
              <span class="primary--text">{{ lessons.filter((x) => x.completed).length }}</span>
              /{{ lessons.length }}
            </span>

            <span class="lesson-complete pl-2">Lessons Completed</span>
          </div>
        </div>
      </div>

      <div class="sub-menu">
        <div class="lesson-title text-uppercase" v-for="lesson in lessons" :key="lesson._id">
          <div
            v-if="!lesson.track || (lesson.track && lesson.track == activeTrack)"
            class="my-3 pa-3 d-flex justify-space-between"
            :class="getLessonClass(lesson)"
            @click="lesson.locked ? lessonLocked(lesson._id) : changeLesson(lesson._id)"
          >
            <!-- <div class="col-1 pa-2">
            <i v-if="lesson.type === 'lecture'" class="fab fa-youtube icon-video"></i>
            <i v-if="lesson.type === 'exercise'" class="fas fa-list icon-list"></i>
            </div>-->

            <div class>{{ lesson.name }}</div>

            <div class="pl-4 pr-0">{{ lesson.videoLength }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  background-color: #f2f2f2;
  min-height: 100vh;
  min-width: 320px;
}
.icon-video,
.icon-list {
  font-size: 25px;
}
.sub-menu {
  background-color: #f2f2f2;
  width: 100%;
}
.module-name {
  color: var(--v-grey-darken3);
  font-size: 13px;
}

.lesson-title {
  min-width: 245px;
  font-size: 14px;
  color: var(--v-accent-lighten3);

  .locked {
    background-color: var(--v-accent-lighten4);
    // color: var(--v-accent-base);
    color: white;
    font-weight: 500;
  }
  .inProgress {
    color: black;
    background-color: white;
    border: var(--v-primary-base) 1px solid;
  }

  .completed {
    background-color: var(--v-primary-darken1);
    border: var(--v-primary-darken1) 1px solid;

    color: white;
    cursor: pointer;
    &:hover {
      color: black;
      background-color: white;
      border: var(--v-primary-base) 1px solid;
      // border: white 1px solid;
    }
  }
}
.lesson-complete {
  line-height: 40px;
  font-size: 14px;
}
@media (min-width: 1600px) {
  .lesson-title {
    font-size: 16px;
  }
}
</style>

<script>
import NavTrail from "@/components/NavTrail.vue";

export default {
  components: { NavTrail },
  props: {
    section: Object,
    lessons: Array,
    activeLessonId: String,
    activeLesson: Object,
    activeTrack: Number,
  },
  data() {
    return {
      sections: null,
      showVideoCensoredDialog: false,
      activeSectionId: null,
      progress: 20,
    };
  },

  methods: {
    changeLesson(lessonId) {
      this.$emit("update:activeLessonId", lessonId);
    },
    lessonLocked(lessonId) {},
    getLessonClass(lesson) {
      // console.log(lesson._id, this.activeLessonId);
      if (lesson._id == this.activeLessonId || this.activeLesson.parentLessonId == lesson._id) {
        return "inProgress";
      } else if (lesson.completed || lesson.inProgress) {
        return "completed";
      } else if (lesson.locked) {
        return "locked";
      }
    },
  },
};
</script>
