<template>
  <div id="exercise-wrapper">
    <v-tabs
      v-if="questions.find((x) => x.type == 'deal-review')"
      v-model="tab"
      background-color="primary"
      dark
      class="tabs"
    >
      <v-tab>Q & A</v-tab>
      <v-tab>Deal Review</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item class="tab-item">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <div class="text-right">
                <v-btn text @click="saveForLater" color="primary">SAVE & EXIT</v-btn>
              </div>
            </v-col>
          </v-row>
          <div id="exercise" v-if="exercise">
            <div v-if="!showGrade" class="exercise-content">
              <v-row class="ml-md-12">
                <v-col cols="12">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex flex-column font-weight-bold primary--text lesson-name">
                      {{ exercise.name }}
                    </div>
                  </div>
                  <div
                    class="text-center font-weight-bold mt-2"
                    v-if="exercise.name == `COMPANY - Your Buyer's Problems`"
                  >
                    Want to complete your PIC offline?
                    <v-btn color="primary" class="ml-2" target="_blank" href="/GapSelling-PIC.pdf">
                      Download PIC
                    </v-btn>
                  </div>
                  <div>
                    <div class="lesson-description mt-2 mb-5" v-html="exercise.description"></div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                v-for="question in questions.filter((x) => x.type != 'deal-review')"
                :key="question._id"
              >
                <v-col cols="12">
                  <v-row class="ml-1 ml-md-10 mb-5" v-if="question.name">
                    <v-col class="pb-1" cols="12">
                      <div>
                        <strong>{{ question.name }}</strong>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="ml-1 ml-md-10 mb-2" v-if="question.description">
                    {{ question.description }}
                  </div>

                  <div
                    class="d-flex"
                    v-if="
                      !question.reviewQuestions ||
                      (question.reviewQuestions &&
                        question.reviewAnswers &&
                        question.reviewAnswers.length > 0 &&
                        question.reviewAnswers[0].answer)
                    "
                  >
                    <div class="pt-0 question-bullet-col text-center ml-1 ml-md-5">
                      <div class="question-bullet-wrapper mr-5" v-if="question.type != 'review'">
                        <img
                          v-if="
                            !question.answers[question.numOfAnswers ? question.numOfAnswers - 1 : 0]
                          "
                          class="question-bullet"
                          src="/img/question-bullet.png"
                        />
                        <img
                          v-if="
                            question.answers[question.numOfAnswers ? question.numOfAnswers - 1 : 0]
                          "
                          class="question-bullet"
                          src="/img/question-bullet-actve.png"
                        />
                      </div>
                    </div>
                    <div
                      class="pt-0 question-col mx-auto"
                      :class="
                        question.reviewQuestions && question.reviewQuestions.length > 0
                          ? 'question-block'
                          : ''
                      "
                    >
                      <div
                        class="row"
                        v-if="
                          question.reviewAnswers &&
                          question.reviewAnswers.length > 1 &&
                          question.type != 'multi-check-review'
                        "
                      >
                        <div
                          :class="
                            'col-12 col-sm-' +
                            (12 / (question.numOfAnswers ? question.numOfAnswers : 1) +
                              (question.reviewAnswers && question.reviewAnswers.length == 1
                                ? 3
                                : 0))
                          "
                          v-for="reviewAnswer in question.reviewAnswers"
                          :key="reviewAnswer.label"
                        >
                          <v-textarea
                            :auto-grow="true"
                            row-height="14"
                            disabled
                            :value="reviewAnswer.answer"
                            :label="reviewAnswer.label + ' - previous answer '"
                          ></v-textarea>
                        </div>
                      </div>
                      <div class="row" v-if="question.type != 'review'">
                        <div
                          class="col-12 mx-2 mx-sm-0"
                          :class="
                            question.numOfAnswers == -1
                              ? 'pl-sm-12 pb-0 mb-0 col-sm-12'
                              : 'col-sm-4'
                          "
                          v-if="question.reviewAnswers && question.reviewAnswers.length == 1"
                        >
                          <v-textarea
                            :auto-grow="true"
                            row-height="14"
                            disabled
                            :value="question.reviewAnswers[0].answer"
                            :label="question.reviewAnswers[0].label"
                          ></v-textarea>
                        </div>
                        <div
                          :class="
                            'col-12 col-sm-' +
                            (question.numOfAnswers == -1
                              ? 12
                              : 12 /
                                  (question.numOfAnswers
                                    ? question.numOfAnswers > 3
                                      ? 3
                                      : question.numOfAnswers
                                    : 1) -
                                (question.reviewAnswers && question.reviewAnswers.length == 1
                                  ? 4
                                  : 0))
                          "
                          v-for="index in question.numOfAnswers && question.numOfAnswers > 0
                            ? question.reviewAnswers && question.reviewAnswers.length == 1
                              ? question.numOfAnswers
                              : question.numOfAnswers
                            : 1"
                          :key="index"
                        >
                          <div class="answer-wrapper">
                            <div v-if="question.type == 'text'">
                              <!-- :disabled="exercise.answeredQuestions" -->

                              <v-text-field
                                :rules="
                                  question.required ? [(v) => !!v || 'Answer is required'] : []
                                "
                                v-model="question.answers[0]"
                                :label="
                                  question.answerLabels ? question.answerLabels[0] : 'Text Answer'
                                "
                              ></v-text-field>
                            </div>
                            <div v-if="question.type == 'textarea'">
                              <v-textarea
                                :rules="
                                  question.required ? [(v) => !!v || 'Answer is required'] : []
                                "
                                v-model="question.answers[0]"
                                :label="
                                  question.answerLabels
                                    ? question.answerLabels[index - 1]
                                    : 'Text Answer'
                                "
                              ></v-textarea>
                            </div>
                            <div v-if="question.type == 'multi-textarea'">
                              <v-textarea
                                :rules="
                                  question.required ? [(v) => !!v || 'Answer is required'] : []
                                "
                                v-model="question.answers[index - 1]"
                                :label="
                                  question.answerLabels
                                    ? question.answerLabels[index - 1]
                                    : 'Text Answer'
                                "
                              ></v-textarea>
                            </div>
                            <div v-if="question.type == 'rating'">
                              <v-rating></v-rating>
                            </div>
                            <div
                              class="d-flex justify-center col-12 ma-0 pa-0"
                              v-if="question.type == 'select'"
                            >
                              <v-radio-group
                                :rules="
                                  question.required ? [(v) => !!v || 'Answer is required'] : []
                                "
                                v-model="question.answers[0]"
                                row
                                class="asg-radio-group col-12 ma-0 pa-0 pt-8"
                              >
                                <div
                                  :class="
                                    'col-' +
                                    (question.direction == 'column'
                                      ? '12'
                                      : Math.ceil(
                                          12 /
                                            (question.options.length ? question.options.length : 1)
                                        ))
                                  "
                                  v-for="option in question.options"
                                  :key="option.value"
                                >
                                  <!-- :label="option.text" -->
                                  <v-radio class="asg-radio" :value="option.value">
                                    <template v-slot:label>
                                      <div class="asg-radio-label">{{ option.text }}</div>
                                    </template>
                                  </v-radio>
                                  <div class="pa-3" v-html="option.description"></div>
                                </div>
                              </v-radio-group>
                            </div>
                            <div class="col-12 text-center" v-if="question.type == 'select'">
                              <div v-html="getNote(question, question.answers[0])"></div>
                            </div>

                            <div
                              class="row justify-start col-12 ma-0 pa-0"
                              v-if="question.type == 'multi-check'"
                            >
                              <div
                                :class="
                                  question.direction == 'column'
                                    ? 'col-12'
                                    : 'col-12 col-sm-6 col-md-4 col-lg-2'
                                "
                                v-for="(option, optionIndex) in question.options"
                                :key="option.value"
                              >
                                <v-checkbox
                                  :label="option.text"
                                  :id="'cb-' + question._id + optionIndex"
                                  class="align-center justify-start ml-5 pt-0 mt-0"
                                  v-model="question.answers[optionIndex]"
                                  false-value="false"
                                  :true-value="option.value"
                                ></v-checkbox>
                              </div>
                            </div>
                            <div
                              class="d-flex justify-center col-12 ma-0 pa-0 flex-column"
                              v-if="question.type == 'multi-check-review'"
                            >
                              <label
                                class="cursor-pointer"
                                :for="'cb-' + question._id + (index - 1)"
                              >
                                <div class="text-center">
                                  <v-textarea
                                    :auto-grow="true"
                                    row-height="14"
                                    disabled
                                    :value="
                                      question.reviewAnswers[index - 1]
                                        ? question.reviewAnswers[index - 1].answer
                                        : ''
                                    "
                                    :label="
                                      question.reviewAnswers[index - 1]
                                        ? question.reviewAnswers[index - 1].label
                                        : ''
                                    "
                                  ></v-textarea>
                                </div>
                              </label>
                              <div class="text-center">
                                <v-checkbox
                                  :id="'cb-' + question._id + (index - 1)"
                                  class="align-center justify-center pt-0 mt-0"
                                  v-model="question.answers[index - 1]"
                                  false-value="false"
                                  true-value="true"
                                ></v-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="errors mb-6" v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <v-row>
                <v-col cols="0" sm="1"></v-col>
                <v-col class="mt-5" cols="12" sm="11">
                  <div class="text-center">
                    <v-btn text @click="save" color="primary">SUBMIT ANSWER & CONTINUE</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="showGrade">
              <div class="row">
                <div v-if="!exercise.showPercentAnswers" class="col-12 text-center">
                  <div v-if="exercise.passingPercent || exercise.showGrade">
                    <div>
                      Your Score:
                      <span :class="passed ? 'green--text' : 'red--text'"
                        >{{ scorePercent.toFixed(0) }}%</span
                      >
                      Correct
                    </div>
                    <div v-if="exercise.passingPercent && !passed">
                      You must have {{ exercise.passingPercent }}% or higher to pass.
                    </div>
                    <div v-if="exercise.passingPercent && passed">You passed the quiz!</div>
                  </div>
                </div>
                <div v-if="exercise.showPercentAnswers" class="text-center mx-auto">
                  <div class="mb-3" v-if="exercise.previousGrade">
                    <div>You Were</div>
                    <div>
                      {{ exercise.previousGrade }}% Problem Centric and
                      {{ 100 - exercise.previousGrade }}% Product Centric
                    </div>
                  </div>

                  <div>You Are</div>
                  <span
                    class="text-capitalize"
                    v-for="(answer, index) in percentAnswersResult"
                    :key="answer.name"
                  >
                    <strong> {{ answer.valuePercent }}%</strong> {{ answer.name }}
                    <span class="text-lowercase" v-if="index + 1 < percentAnswersResult.length"
                      >and</span
                    >
                  </span>
                  <div class="mt-2" v-if="exercise.answerDescriptionFail && !passed">
                    {{ exercise.answerDescriptionFail }}
                  </div>
                  <div class="mt-2" v-if="exercise.answerDescriptionPass && passed">
                    {{ exercise.answerDescriptionPass }}
                  </div>
                  <div class="text-center" v-if="pieChartData">
                    <pie-chart
                      class="chart mt-12 mx-auto"
                      :chartdata="pieChartData"
                      :options="pieTimeReportsChartsOptions"
                      :height="200"
                    />
                  </div>
                </div>
                <div class="col-12 text-center" v-if="exercise.showPercentAnswers">
                  <v-btn text @click="loadNextLesson(true)" color="primary">CONTINUE</v-btn>
                </div>
                <div class="col-12 text-center" v-if="!exercise.showPercentAnswers">
                  <v-btn
                    class="mt-3"
                    v-if="!passed && quizTakeCount > 1 && exercise.skipNextLessonId"
                    text
                    @click="loadNextLesson(quizTakeCount > 1)"
                    color="primary"
                    >SKIP QUIZ AND CONTINUE</v-btn
                  >
                  <!-- v-if="!passed" -->
                  <v-btn class="mt-3" text @click="loadNextLesson()" color="primary"
                    >CONTINUE</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item class="tab-item">
        <v-row
          v-for="(question, index) in questions.filter((x) => x.type == 'deal-review')"
          :key="question._id"
        >
          <v-col cols="12" class="pa-sm-8">
            <div class="d-flex flex-column font-weight-bold primary--text lesson-name">
              {{ question.name }}
            </div>
            <div class="row" v-if="question.reviewAnswers && question.reviewAnswers.length > 1">
              <div
                :class="'col-12 col-sm-' + getBreaks(question, index)"
                v-for="reviewAnswer in question.reviewAnswers"
                :key="reviewAnswer.label"
              >
                <v-textarea
                  :auto-grow="true"
                  row-height="14"
                  disabled
                  :value="reviewAnswer.answer"
                  :label="reviewAnswer.label"
                ></v-textarea>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss">
.v-input--selection-controls__input .v-label,
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls .v-radio > .v-label {
  // max-width: 90% !important;
  // max-width: 40% !important;
  // overflow-wrap: normal;
  // word-wrap: break-word;
  // white-space: normal;
}
// .asg-radio-label {
//   max-width: 90% !important;
//   // max-width: 40% !important;
//   overflow-wrap: normal;
//   word-wrap: break-word;
//   white-space: normal;
// }
</style>
<style lang="scss" scoped>
.question-block {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e27f6b;
  // border: solid #fff 10px;
}
.tab-item {
  // min-height: 90vh;
}
.asg-radio-group,
.asg-radio {
  // min-width: 300px;
  max-width: 900px;
}
.asg-radio-group div {
  width: 900x;
}

#exercise {
  // height: 100vh;
  // max-width: 1000px;
  margin: auto;
  padding: 3px;
  background-color: #f2f2f2;
}

.answer-wrapper {
  padding: 5px 10px;
  border: 1px solid #e27f6b;
  background-color: #ffffff;
}
.question-block .answer-wrapper {
  border: 0;
}
.question-col {
  width: 100%;
}
.question-bullet {
  position: absolute;
  top: 38%;
  right: 0;
}
.question-bullet-col {
  width: 0px;
}
.question-bullet-wrapper {
  position: relative;
  height: 100%;
  display: none;
}

@media (min-width: 460px) {
  .asg-radio-group,
  .asg-radio {
    // min-width: 700px;
    max-width: 900px;
  }
  .question-bullet-col {
    width: 50px;
  }
  .question-bullet-wrapper {
    display: block;
  }
  #exercise {
    padding: 20px;
  }
}
</style>

<script>
// import analytics from '../module/analytics';
import progress from "../module/progress";
import SAVE_ANSWER from "../graphql/SaveAnswer.gql";
import PieChart from "./PieChart.js";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      exerciseDialogOpen: false,
      errors: [],
      valid: null,
      scorePercent: 0,
      showGrade: false,
      passed: false,
      percentAnswersResult: null,
      quizTakeCount: 0,
      tab: 0,
      activeTrack: 1,
      pieChartData: null,
      pieTimeReportsChartsOptions: {
        responsive: true,
        legend: {
          // display:false,
          labels: {
            fontColor: "black",
            fontStyle: "bold",
          },
        },
      },
    };
  },
  computed: {
    questions() {
      let questions;
      if (this.part) {
        questions = this.exercise.questions.filter((e) => e.partNumber == this.part);
      } else {
        questions = this.exercise.questions;
      }
      return questions;
    },
  },
  props: {
    exercise: null,
    part: Number,
    showDialog: Boolean,
  },
  watch: {
    showDialog(newVal) {
      this.exerciseDialogOpen = newVal;
    },
    // exercise(exerciseVal) {
    //   //  {{ exercise.previousGrade }}% Problem Centric and
    //   //             {{ 100 - exercise.previousGrade }}% Product Centric
    //   this.pieChartData = {
    //     labels: ["Problem Centric", "Product Centric"],
    //     datasets: [
    //       {
    //         label: "Dataset 1",
    //         backgroundColor: ["#89a8ff", "#89ff91"],
    //         // borderColor: "#ff0000",
    //         borderWidth: 1,
    //         data: [
    //           exerciseVal.previousGrade ? exerciseVal.previousGrade : 0,
    //           exerciseVal.previousGrade ? this.progressOverviewReport.completed : 0
    //         ]
    //       }
    //     ]
    //   };
    // }
  },
  methods: {
    async saveForLater(evt) {
      // save answers
      const answerList = this.exercise.questions.map((q, index) => {
        return {
          questionId: q._id,
          answers: q.answers ? q.answers : [q.answer],
        };
      });
      const saveAnswersResult = await this.$apollo
        .mutate({
          mutation: SAVE_ANSWER,
          variables: {
            lessonId: this.exercise._id,
            answerList: answerList,
            saveForLater: true,
          },
        })
        .catch((error) => {
          this.saveAnswerError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "Save note error.";
        });
      console.log("saved");

      this.answersAreSaved = saveAnswersResult.data.saveAnswers;
      this.$emit("update:showDialog", false);
      this.$router.push({ name: "course-list" });
    },
    async save(evt) {
      const self = this;
      evt.preventDefault();
      const isValid = this.$refs.form.validate();
      console.log("isValid", isValid);
      if (!isValid) {
        return;
      }
      let pass = null;
      if (this.exercise.passingPercent || this.exercise.showGrade) {
        let score = 0;
        let numberOfAnswers = 0;
        this.exercise.questions.forEach((q) => {
          if (q.options) {
            if (q.type == "multi-check-review") {
              q.options.forEach((o, index) => {
                const reviewAnswerExists = q.reviewAnswers.find((x) => x.answer);
                if (reviewAnswerExists) {
                  q.answers[index] = q.answers[index] == "true" ? "true" : "false";
                  numberOfAnswers++;
                  if (
                    (o.correct && q.answers[index] == "true") ||
                    (!o.correct && q.answers[index] == "false")
                  ) {
                    score++;
                  }
                  console.log("score ++", score);
                }
              });
            } else if (q.type == "multi-check") {
              numberOfAnswers++;
              const allCorrectAnswers = q.options.filter((o) => o.correct);
              let numCorrect = 0;
              q.options.forEach((o, oindex) => {
                const correctAnswer =
                  q.options[oindex] && q.options[oindex].correct ? q.options[oindex] : null;
                if (correctAnswer && q.answers[oindex] && q.answers[oindex] != "false") {
                  numCorrect++;
                  if (numCorrect == allCorrectAnswers.length) {
                    score++;
                  }
                }
              });
            } else {
              numberOfAnswers++;
              const correctAnswer = q.options.find((o) => o.correct);
              if (correctAnswer && q.answers[0] == correctAnswer.value) {
                score++;
              }
            }
            // const correctAnswers = q.options.filter(o => o.correct).map(x=>x.value);
            // const inCorrectAnswers = q.options.filter(o => !o.correct);

            //   q.options.forEach((option, index) => {
            //     if (
            // option.correct && option.value ==

            //       ) {
            //       score++;
            //     }
            //   });

            // const correctAnswer = q.options.find(o => o.correct);
            // if (q.answers[0] == correctAnswer.value) {
            //   score++;
            // }
          }
        });
        const scorePercent = (score / numberOfAnswers) * 100;
        this.scorePercent = scorePercent;
        if (self.exercise.passingPercent) {
          if (scorePercent >= self.exercise.passingPercent) {
            pass = true;
            this.passed = true;
          } else {
            pass = false;
            this.passed = false;
          }
        }
      }

      let percentAnswersResult = [];
      if (self.exercise.showPercentAnswers) {
        this.exercise.questions.forEach((q) => {
          const answerName = q.answers[0].split("_").join(" ");
          const currentAnswerIndex = percentAnswersResult.findIndex((x) => x.name == answerName);
          if (currentAnswerIndex > -1) {
            let val = percentAnswersResult[currentAnswerIndex].value;
            val++;
            percentAnswersResult[currentAnswerIndex].value = val;
          } else {
            percentAnswersResult.push({ name: answerName, value: 1 });
          }
          percentAnswersResult.forEach((p) => {
            p.valuePercent = ((p.value / this.exercise.questions.length) * 100).toFixed(0);
          });
          // percentAnswersResult.push(q.answers[0]);
          // if (q.answers) {
          // q.answers.forEach((o, index) => {
          //   //                    q.answers[index]
          //   // percentAnswersResult.push(q.answers[index])
          //   // currentAnswer = percentAnswersResult.find(a=>)
          // });
          // }
        });
      }
      this.percentAnswersResult = percentAnswersResult;

      if (
        percentAnswersResult &&
        percentAnswersResult.length > 0 &&
        percentAnswersResult[0].name &&
        percentAnswersResult[0].name.toLowerCase() == "problem centric"
      ) {
        let labels = [];
        let data = [];
        percentAnswersResult.forEach((answer) => {
          data.push(answer.valuePercent);
          labels.push(answer.name);
        });

        // <span
        //                 class="text-capitalize"
        //                 v-for="(answer, index) in percentAnswersResult"
        //                 :key="answer.name"
        //               >
        //                 {{ answer.valuePercent }}% {{ answer.name }}
        //                 <span class="text-lowercase" v-if="index + 1 < percentAnswersResult.length"
        //                   >and</span
        //                 >
        //               </span>

        this.pieChartData = {
          labels: labels,
          datasets: [
            {
              label: "Dataset 1",
              backgroundColor: ["#ff6060", "#c6c6c6"],
              // borderColor: "#ff0000",
              borderWidth: 1,
              data: data,
            },
          ],
        };
      }

      // scroll to top
      window.scrollTo(0, 0);

      const result = await progress.update(
        "exercise ended",
        this.exercise.section._id,
        this.exercise._id
      );
      let answerOpton;
      const answerList = this.exercise.questions.map((q, index) => {
        if (q.options) {
          answerOpton = q.options.find((o) =>
            q.answers ? o.value == q.answers[0] : q.answer ? o.value == q.answers[0] : false
          );
        }
        return {
          questionId: q._id,
          answers: q.answers ? q.answers : [q.answer],
        };
      });
      if (answerOpton && answerOpton.setTrack) {
        this.$emit("update:activeTrack", answerOpton.setTrack);
      }

      // save answers
      const saveAnswersResult = await this.$apollo
        .mutate({
          mutation: SAVE_ANSWER,
          variables: {
            lessonId: this.exercise._id,
            answerList: answerList,
            pass: pass,
            saveForLater: false,
          },
        })
        .catch((error) => {
          this.saveAnswerError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "Save note error.";
        });
      console.log("saved");

      this.answersAreSaved = saveAnswersResult.data.saveAnswers;
      // end save answers

      if (this.exercise.showGrade) {
        this.showGrade = true;
        this.quizTakeCount++;
      } else {
        this.loadNextLesson();
      }
    },
    loadNextLesson(forcePass) {
      const self = this;
      // check if we should end the lesson
      let loadNextLesson = false;
      let nextLessonId;
      if (
        !this.exercise.questions[0].triggerEvents ||
        (this.exercise.questions[0].triggerEvents &&
          !this.exercise.questions[0].triggerEvents[0].time)
      ) {
        loadNextLesson = true;
        //
        if (this.exercise.failNextLessonId || this.exercise.passNextLessonId) {
          nextLessonId = forcePass
            ? this.exercise.skipNextLessonId
            : this.passed
            ? this.exercise.passNextLessonId
            : this.exercise.failNextLessonId;
        }
        this.$emit("ended", false);
      }
      this.$emit("submit", {
        exercise: this.exercise,
        loadNextLesson: loadNextLesson,
        nextLessonId: nextLessonId,
      });

      this.exerciseDialogOpen = false;
      this.$emit("update:showDialog", false);
      setTimeout(() => {
        self.showGrade = false;
      }, 100);
    },
    getNote(question, answer) {
      const option = question.options.find((x) => x.value == answer);
      return option ? option.note : null;
    },
    getBreaks(question, index) {
      let breakPoint = "12";

      switch (question.numOfAnswers) {
        case 1:
          breakPoint = "12";
          break;
        case 2:
          breakPoint = "6";
          break;
        case 3:
          breakPoint = "4";
          break;
        case 4:
          breakPoint = "3";
          break;
        case 5:
          breakPoint = "2";
          break;

        default:
          break;
      }
      // ((12 / (question.numOfAnswers ?  question.numOfAnswers : 1))+(question.reviewAnswers && question.reviewAnswers.length == 1?3:0))

      return breakPoint;
    },
  },
};
</script>
