<template>
  <div>
    <div class="pa-5" v-for="section in sections" :key="section._id">
      <div v-for="lesson in section.lessons" :key="lesson._id">
        <div class="text-left" v-if="lesson.userNote && lesson.userNote.body">
          <span class="text-left">
            <a
              :href="`/section/${section._id}?lessonid=${lesson._id}`"
            >{{section.name}} > {{lesson.name}}</a>
          </span>
          <div class="pl-2" v-html="lesson.userNote.body"></div>
        </div>
        <!-- <Notes class="text-editor" :lesson="lesson"></Notes> -->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { mapState } from "vuex";
import GET_SECTION from "../graphql/GetSection.gql";

export default {
  name: "NotesList",
  props: {
    showDialog: Boolean,
  },
  data() {
    return {
      sections: null,
    };
  },
  watch: {
    showDialog(newVal, oldVal) {
      if (newVal) {
        this.getCourseList();
      }
    },
  },
  mounted() {
    this.getCourseList();
  },
  computed: mapState(["auth"]),
  methods: {
    async getCourseList() {
      const self = this;
      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            groupId: "default",
          },
        })
        .catch((error) => {
          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      // console.log("getSectionResult", getSectionResult.data.section);
      this.sections = getSectionResult.data.section;
    },
  },
};
</script>
