 <template>
  <div>
    <!-- <v-textarea :label="`Lesson Notes: ${lesson.name}`" rows="22"></v-textarea> -->
    <quill-editor
      ref="txtEditor"
      v-model="body"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <div class="btn-wrapper pt-4 pb-2 text-center">
      <v-btn @click="saveNote" class="save-note" text color="primary"
        >Save Note</v-btn
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn-wrapper {
  background: rgb(242, 242, 242);
}
</style>

 <script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import UPDATE_NOTE from "../graphql/UpdateNote.gql";

export default {
  name: "Notes",
  components: {
    quillEditor,
  },
  props: {
    lesson: Object,
  },
  data() {
    return {
      body: null,
      lastBody: null,
      autoSaveNoteTimeout: null,
      saveNoteError: null,
      noteIsSaved: true,
      editorOption: {
        modules: {
          toolbar: [["bold", "italic", "underline"]],
        },
      },
    };
  },
  mounted() {
    this.body = this.lesson.userNote ? this.lesson.userNote.body : null;
    this.autoSaveNote();
  },
  watch: {
    lesson(localLesson) {
      this.body = this.lesson.userNote ? localLesson.userNote.body : null;
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
      this.saveNote();
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
      this.noteIsSaved = null;
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.body = html;
    },
    autoSaveNote() {
      const self = this;
      clearTimeout(this.autoSaveNoteTimeout);
      this.autoSaveNoteTimeout = setTimeout(() => {
        self.saveNote();
        self.autoSaveNote();
      }, 2000);
    },
    async saveNote() {
      if (this.body && this.lastBody != this.body) {
        this.lastBody = this.body;
        const saveNoteResult = await this.$apollo
          .mutate({
            mutation: UPDATE_NOTE,
            variables: {
              lessonId: this.lesson.parentLessonId
                ? this.lesson.parentLessonId
                : this.lesson._id,
              body: this.body,
            },
          })
          .catch((error) => {
            this.saveNoteError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Save note error.";
          });
        console.log("saved");

        this.noteIsSaved = saveNoteResult.data.updateNote;
      }
    },
  },
};
</script>

 