// export default function track(type, event, properties) {
//   console.log('type', type);
//   console.log('event', event);
//   console.log('properties', JSON.stringify(properties));
// }
import { InMemoryCache } from "apollo-cache-inmemory";
import { createProvider } from "../vue-apollo";
import PROGRESS_UPDATE from "../graphql/ProgressUpdate.gql";

const cache = new InMemoryCache();

const progress = {
  async update(event, sectionId, lessonId, videoTime) {
    return new Promise(async function(resolve, reject) {
      const provider = createProvider({ cache });
      const apollo = provider.defaultClient;

      const result = await apollo
        .mutate({
          mutation: PROGRESS_UPDATE,
          variables: {
            event,
            sectionId,
            lessonId,
            videoTime
          }
        })
        .catch(error => {
          console.log("error.message", error.message);
          // this.getLessonError = error.message
          //   ? error.message.replace('GraphQL error: ', '')
          //   : 'error.';
        });

      resolve(result);
    });
  }
};

export default progress;
